* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

html {
    height: auto;
    min-height: 100%;
    background-color: $backGradColor2;
    background: -moz-linear-gradient(top, $backGradColor1 0%, $backGradColor2 100%) /* FF3.6+ */;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $backGradColor1), color-stop(100%, $backGradColor2)) /* Chrome,Safari4+ */;
    background: -webkit-linear-gradient(top, $backGradColor1 0%, $backGradColor2 100%) /* Chrome10+,Safari5.1+ */;
    background: -o-linear-gradient(top, $backGradColor1 0%, $backGradColor2 100%) /* Opera 11.10+ */;
    background: -ms-linear-gradient(top, $backGradColor1 0%, $backGradColor2 100%) /* IE10+ */;
    background: linear-gradient(to bottom, $backGradColor1 0%, $backGradColor2 100%) /* W3C */; }

body {
    @extend .clearfix;
    width: $bodyWidth + ($spacing * 2);
    margin: 40px auto 80px auto;
    border: $spacing solid $pageColor;
    position: relative;
    background-color: $baseColor;
    background: -moz-linear-gradient(top, $baseGradColor1 0%, $baseGradColor2 100%) /* FF3.6+ */;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $baseGradColor1), color-stop(100%, $baseGradColor2)) /* Chrome,Safari4+ */;
    background: -webkit-linear-gradient(top, $baseGradColor1 0%, $baseGradColor2 100%) /* Chrome10+,Safari5.1+ */;
    background: -o-linear-gradient(top, $baseGradColor1 0%, $baseGradColor2 100%) /* Opera 11.10+ */;
    background: -ms-linear-gradient(top, $baseGradColor1 0%, $baseGradColor2 100%) /* IE10+ */;
    background: linear-gradient(to bottom, $baseGradColor1 0%, $baseGradColor2 100%) /* W3C */; }

.loggedIn {
    font-size: $smallSize;
    position: absolute;
    top: -45px;
    right: 0;
    color: $textColorNegative;
    line-height: 1.8em;

    .button {
        font-size: inherit;
        line-height: inherit;
        display: inline-block;
        text-decoration: none;
        background: $textColorNegative;
        color: $textColor;
        padding: 0 0.4em;
        margin: 0 0 0 1em;
        border-radius: 2px;

        &:hover {
            background: $bfred; } } }

#site-header {
    padding: 0;
    margin: 0;
    float: right;

    a {
        @include replace-text("./images/logo.gif");
        background-position: center bottom;
        height: $logoElHeight;
        width: $bodyWidth - $mainWidth;
        display: block; } }

@import "includes/header";
@import "includes/menu";
@import "includes/content";
@import "includes/footer";

small#credit {
    color: $textColorNegative;
    position: absolute;
    white-space: nowrap;
    bottom: -$spacing * 3;
    right: 0;
    font-size: 10px;

    .legal {
        &:after {
            content: " | "; } }

    a {
        color: inherit;

        &:hover {
            color: $textColorNegative2; } } }

button#persona {
    @include replace-text("./images/persona-sign-in.png");
    background-color: transparent;
    display: block;
    border: none;
    width: 95px;
    height: 25px; }

a.cms-edit {
    display: block;
    clear: both;
    width: 60px;
    text-align: center;
    background: $bfred;
    padding: 0 1em;
    font-size: 12px;
    line-height: 2em;
    color: $bfwhite;
    text-decoration: none;
    border-radius: 2px;
    margin: 0.4em 0 1em 0;

    &:hover {
        background: $bfred3; } }

main {
    min-height: 420px; }

@import "includes/home";
