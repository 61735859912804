form {
	margin-left: -20px;

	.field {
		display: block;
		margin: 0 0 1em 0;

		input,
		textarea {
			display: block;
			width: 100%; } }

	fieldset.half-width {
		width: 50%;
		float: left;
		padding-left: $spacing;

		&:first-child {
			padding-left: 20px; } }

	input[type='submit'],
	button[type='submit'] {
		clear: both;
		display: block;
		margin-left: 20px; } }

button,
.button {
	display: block;
	border: none;
	background: $bfred3;
	color: $bfwhite;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	padding: 13px 2em;
	font-size: 15px;
	line-height: 1em;
	font-weight: bold;
	letter-spacing: 1px;
	margin: 1em 0;

	&:hover {
    	background: lighten($bfred3, 10%);
    	color: $bfwhite; } }
