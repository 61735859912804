@import "includes/icons";

$headingSize: 36px;
$bodySize: 16px;
$lineHeight: 1.6em;
$smallSize: 12px;

@mixin font-title {
    font-family: "alternate-gothic-no-3-d", Arial, Helvetica, sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased; }

@mixin font-promo-title {
    font-family: "ff-meta-web-pro", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: none;
    -webkit-font-smoothing: antialiased; }

@mixin font-body {
    font-family: "ff-dagny-web-pro", Arial, Helvetica, sans-serif;
    font-weight: normal;
    color: $bfblack; }

address {
    font-style: normal; }

body {
    @include font-body;
    font-size: $bodySize;
    line-height: $lineHeight; }

h1, h2, h3 {
    @include font-title;
    color: $bfred;
    font-size: $headingSize;
    line-height: 1em;
    margin: 1em 0 0.8em 0; }

h2 {
    color: $bfblack;
    margin: 0.6em 0 0.4em 0; }

h3 {
    font-size: $headingSize / 3 * 2; }


a {
    color: $bfLinkColor;

    &:hover {
        color: $bfLinkColorHover; } }

p,
h4 {
    font-size: inherit;
    line-height: $lineHeight;
    margin: 0 0 ($lineHeight * 0.7) 0; }

h4 {
    margin-top: $lineHeight; }

table {
    font-size: $smallSize;
    text-align: center; }
