$contentBgColor: $bfwhite;

$contentMargin: 60px;
$contentMarginSmall: 30px;

$controlSize: 40px;

$contentTabColor: darken($mainSummaryBg, 5%);

main {
    width: $mainWidth;
    background: $contentBgColor;
    float: left;
    padding: $contentMarginSmall $contentMargin $contentMargin $contentMargin;
    min-height: 320px;

    &.no-tabs {
        padding-bottom: $contentMargin; } }

@import "includes/forms";

blockquote {
    position: relative;
    margin: $contentMarginSmall $contentMargin;
    font-style: italic;
    quotes: "\201C""\201D""\2018""\2019";

    &:before {
        @include font-promo-title;
        position: absolute;
        top: 0;
        right: 100%;
        color: #ccc;
        content: open-quote;
        font-size: 6em;
        line-height: 0.6em;
        margin-right: 0.15em; }

    &:after {
        @include font-promo-title;
        position: absolute;
        bottom: 0;
        left: 100%;
        color: #ccc;
        content: close-quote;
        font-size: 6em;
        line-height: 0em; } }

table {
    background: #F7F7F7;
    margin: 1em 0;
    table-layout: fixed;

    thead {
        background: #D2D2D2;
        font-weight: bold; }

    tbody {
        tr:nth-child(even) {
            background: #F0F0F0; } }

    td,
    th {
        padding: 0.4em 0.8em;
        border-bottom: 1px solid #fff; }

    &.no-color {
        background: none;

        thead,
        tbody,
        tr,
        td,
        th {
            background: none !important; } } }


.link a,
a.link {
    display: block;
    border-top: 1px solid #9D8787;
    text-decoration: none;
    text-transform: uppercase;
    color: $bfred;
    font-weight: bold;
    line-height: 3em;

    &:hover {
        text-decoration: underline; } }

.sub-page {
    background: $contentTabColor;
    margin: $contentMargin (-$contentMargin) (-$contentMargin) (-$contentMargin);
    padding: $contentMarginSmall $contentMargin $contentMargin $contentMargin; }

ul.tabs {
    list-style-type: none;
    padding: 0;
    margin: $contentMargin 0 0 0;
    line-height: 3em;

    & + .sub-page {
        margin-top: 0; }

    li.tab {
        display: block;
        float: left;
        margin-right: 5px;

        a {
            display: block;
            padding: 0 1.5em;
            text-decoration: none;
            color: $bfblack;
            border-top: $contentBgColor 3px solid;

            &:hover {
                background: $contentTabColor; } }

        &.active a {
            background: $contentTabColor;
            color: $bfblack;
            border-top: $bfred 3px solid; } } }

li {
    a.block-item {
        font-size: 14px;
        line-height: 2em;
        display: block;
        margin-bottom: 1em;
        color: $textColor;
        background: $textColorNegative;
        padding: 0 1em;
        text-decoration: none;

        &:hover {
            background: $textColor;
            color: $textColorNegative; } } }

.animal {
    .short-description {
        text-align: right;
        float: right;
        font-style: italic;
        width: 60%;

        .sire,
        .dam {
            float: left;
            width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 2em;
            text-align: left; } } }

.sire-detail,
.sale-lot-detail {
    height: auto;
    margin: 0;
    padding: 0 $contentMarginSmall;
    max-height: 0;
    overflow: hidden;
    font-size: 12px;
    line-height: 1.6em;
    background: $contentBgColor;
    -webkit-transition: max-height 0.5s, padding-top 0.5s, padding-bottom 0.5s;
    -moz-transition: max-height 0.5s, padding-top 0.5s, padding-bottom 0.5s;
    transition: max-height 0.5s, padding-top 0.5s, padding-bottom 0.5s;

    .row {
        margin: 10px 0 0 (-$contentMarginSmall);

        &:first-child {
            margin-top: 0; } }

    p {
        margin-bottom: 1em; }

    table {
        width: 100%;
        margin-top: 0; }

    .pictures {
        position: relative;
        float: right;
        width: 220px;

        ul {
            list-style-type: none;
            padding: 0;
            float: right;
            margin: 0; }

        img {
            width: 220px;
            cursor: zoom-in; } } }

.description,
.pictures {
    width: 50%;
    float: left;
    padding-left: $contentMarginSmall; }

.production,
.pedigree {
    width: 50%;
    float: left;
    padding-left: $contentMarginSmall; }

.pedigree {
    background-position: $contentMarginSmall bottom; }


.slideshow-controls {
    font-size: $controlSize * 0.8;

    .label {
        @extend .visuallyhidden; }

    .icon {
        display: block;
        position: absolute;
        top: $controlSize / -2;
        text-align: center;
        line-height: $controlSize;
        width: $controlSize;
        color: $contentBgColor;
        background: $bfred; }

    .prev,
    .next {
        position: absolute;
        width: $controlSize;
        height: 50%;
        top: 50%;

        &:hover .icon {
            background: lighten($bfred3, 10%); } }

    .prev {
        left: 0;

        .icon {
            left: $controlSize / -2; }

        &:before {
            position: absolute;
            display: block;
            content: '';
            height: 200%;
            width: 100%;
            right: 0;
            bottom: 0; } }

    .next {
        right: 0;

        .icon {
            right: $controlSize / -2; }

        &:before {
            position: absolute;
            display: block;
            content: '';
            height: 200%;
            width: 100%;
            left: 0;
            bottom: 0; } } }

.animal.active {
    .sire-detail,
    .sale-lot-detail {
        padding-top: $contentMarginSmall;
        padding-bottom: $contentMarginSmall;
        max-height: 1000px; } }

ul.sires,
ul.sale-lots {
    list-style-type: none;
    padding: 0;
    margin: 0 0 $contentMarginSmall 0;

    li.sire,
    li.sale-lot {
        display: block;

        & > a {
            border-bottom: 1px solid #ccc;
            line-height: 3em;
            padding: 0 $contentMarginSmall;
            display: block;
            text-decoration: none;
            color: $textColor;
            position: relative;

            &:before {
                @include icon;
                content: "\f107";
                font-size: 1.4em;
                line-height: inherit;
                position: absolute;
                left: 10px;
                top: 0; }

            &:hover {
                background: darken($contentTabColor, 5%); } }

        &.active {
            & > a {
                background: darken($contentTabColor, 8%);

                &:before {
                    content: ''; } } }


        &:first-child > a,
        &.active + li.sire > a,
        &.active + li.sale-lot > a {
            border-top: 1px solid #ccc; } } }

.sale-meta {
    .location {
        margin: 0.6em 0;
        font-size: 14px;
        font-weight: bold; } }

.pedigree {
    background-image: url('./images/pedigree-three.svg');
    background-repeat: no-repeat;
    background-size: 200px 375px;

    div {
        font-size: $smallSize;
        line-height: 22px;
        text-align: center;
        background: #ddd;
        padding: 0 0.4em;
        margin-bottom: 3px;
        border-radius: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }

    .name {
        margin-right: 60%;
        background: #ccc;
        font-weight: bold; }
    .p1 {
        margin-left: 20%;
        margin-right: 40%; }
    .p2 {
        margin-left: 40%;
        margin-right: 20%; }
    .p3 {
        margin-left: 60%; }

    &.two-levels {
        background-image: url('./images/pedigree-two.svg');
        background-repeat: no-repeat;
        background-size: 175px 175px;

        .name {
            margin-right: 50%;
            background: #ccc; }
        .p1 {
            margin-left: 25%;
            margin-right: 25%; }
        .p2 {
            margin-left: 50%;
            margin-right: 0%; } } }


#page-home {

    main.thirds {
        padding: $spacing 0;
        border-top: $pageColor $spacing solid;
        background: -moz-linear-gradient(top, $contentBgColor 0%, $contentBgColor 220px, $contentTabColor 220px, $contentTabColor 100%); /* FF3.6+ */;
        background: -webkit-linear-gradient(top, $contentBgColor 0%, $contentBgColor 220px, $contentTabColor 220px, $contentTabColor 100%); /* Chrome10+,Safari5.1+ */;
        background: -o-linear-gradient(top, $contentBgColor 0%, $contentBgColor 220px, $contentTabColor 220px, $contentTabColor 100%); /* Opera 11.10+ */;
        background: -ms-linear-gradient(top, $contentBgColor 0%, $contentBgColor 220px, $contentTabColor 220px, $contentTabColor 100%); /* IE10+ */;
        background: linear-gradient(to bottom, $contentBgColor 0%, $contentBgColor 220px, $contentTabColor 220px, $contentTabColor 100%); /* W3C */;


        .third {
            width: (100% / 3);
            float: left;
            padding-left: $contentMarginSmall;

            h3 {
                margin-bottom: 0.5em; } }

        .image {
          height: 205px;
          margin-left: -11px;

          img {
            display: block;
            margin: 0 auto; } }

        .row {
            margin: 0 $contentMarginSmall 0 0; } } }

.person {
    .col.photo {
        width: auto;

        & + .col {
            float: none;
            margin-left: 128px + $contentMarginSmall;
            width: auto; } }

    .col > *:first-child {
        margin-top: 0; } }

#page-contact {
    table,
    thead,
    tbody,
    tfoot,
    tr,
    th,
    td {
        background: none; }

    table {
        line-height: 1.3em; }

    h3 + table {
        margin-top: 0; } }

.row {
    margin: $contentMarginSmall 0;

    .title {
        margin: 0; }

    .content *:first-child {
        margin-top: 0; } }

.col {
    float: left;
    width: 50%; }

.contact {
    .content :first-child {
        margin-top: 10px; }

    .content.address :first-child {
        margin-top: 2px;

        td {
            padding: 0.4em 0; } }

    th {
        padding: 0.4em 0; } }

.map-container.row {
    margin-top: 0; }

.map {
    padding-right: 25px;

    & > * {
        height: 250px; }

    h3 {
        height: auto; } }

.map:first-child {
    padding-left: 0; }

.testimonial-logo,
.testimonial-signature {
    float: right;
    margin-right: $contentMargin; }

.testimonial-photo {
    float: left;
    margin: 0 $contentMarginSmall $contentMarginSmall 0;
    text-align: center; }

img.face {
    border: 1px solid #ddd; }

#modal {
    background: transparentize($menuBg, 0.2);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;

    .modal-inner {
        float: right;
        right: 50%;
        position: relative;
        max-width: 100%;
        max-height: 100%; }

    .modal-content {
        float: right;
        right: -50%;
        position: relative;
        background: $contentBgColor;
        margin: $contentMargin;
        max-width: 100%;
        max-height: 100%;

        img {
            max-width: 100%;
            min-width: 100px;
            min-height: 100px; }

        .close {
            position: fixed;
            top: $contentMarginSmall / 2;
            right: $contentMarginSmall / 2;
            color: $bfwhite;
            text-decoration: none;
            font-size: $controlSize;
            line-height: $controlSize;
            text-align: right;

            .label {
                @extend .visuallyhidden; } } } }
