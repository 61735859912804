#page-home {

  #feature {
    .feature {
      position: absolute;
      bottom: 0;
      left: 0;
      background: $bfred;
      color: #dacecf;
      height: 150px;
      width: $menuWidth;
      padding: 0 10px 0 20px;
      text-decoration: none;
      
      img {
        float: left;
        margin-right: 10px;
        margin-top: 24px;
      }

      h2 {
        @include font-title;
        font-size: 24px;
        line-height: 1em;
        margin: 24px 0 0.2em 0;
        padding: 0;
        color: #FFF;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      
      p {
        font-size: 14px;
      }

      a {
        color: #fff
      }
    }

    a.feature:hover {
      background: #EF0012;
    }
  }

  #ourmissionColumn {
    width: 483px;
    height: 150px;
    background: #fff;
    color: #343434;
    float: right;
    padding: 0 10px 0 20px;
    margin-bottom: 15px;
    
    h1 {
      color: $bfred;
    }
      
    a {
      color: #af4343;
      font-weight: bold;
      text-decoration:none;
      
      &:hover {
        text-decoration:underline;
        color: #af010a;
      }
    }
  }

  #main-home {
    clear: both;
    background-color: #EEE6E6;
    
    article {
      float: left;
      width: 33%;
      
      .section-image {
        background-color: #FFF;
        padding: 15px 9px 15px 0;
      }
      
      .section-text {
        padding: 0 20px 15px 11px;
      }
      
      header {
        color: $bfred;
      }
      
      .link {
        border-top: 1px solid #9D8787;
        padding-top: 7px;
        
        a {
          text-transform: uppercase;
          color: $bfred;
          font-weight: bold;
        }
      }
      
      &:first-child {
        width: 34%;
        
        .section-image {
          padding: 15px 9px 15px 9px;
        }
        
        .section-text {
          padding: 0 20px 15px 20px;
        }
      }
    }
  }

}
