@font-face {
    font-family: 'bficonfont';
    src: url('fonts/bficonfont.eot');
    src: url('fonts/bficonfont.eot?#iefix') format('embedded-opentype'),
        url('fonts/bficonfont.woff') format('woff'),
        url('fonts/bficonfont.ttf') format('truetype'),
        url('fonts/bficonfont.svg#icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

@mixin icon {
    font-family: 'bficonfont';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
    @include icon;
}

.icon-times-circle:before {
    content: "\f057";
}

.icon-angle-left:before {
    content: "\f104";
}

.icon-angle-right:before {
    content: "\f105";
}

.icon-angle-down:before {
    content: "\f107";
}
