nav {
  position: absolute;
  top: 0;
  left: 0;
  width: $menuWidth;
  overflow: visible;
  z-index: 2;
  @include font-title;
  font-size: 30px;
  line-height: 36px;
  color: #FFF;
  text-shadow: 0px 0px 1px #484848;
  height: $menuHeight;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }

  li {
    a {
      display: block;
      width: 100%;
      height: 41px;
      padding: 5px 0 0 20px;
      margin: 1px 0 0 0;
      background: $menuBg;
      color: #FFF;
      text-transform: uppercase;
      text-decoration: none;
      transition: width ease .3s;

      &:hover {
        color: #efe6e6;
        width: 277px; } }

    &:nth-child(1) a {
      background: transparentize($menuBg, 0.6); }

    &:nth-child(2) a {
      background: transparentize($menuBg, 0.5); }

    &:nth-child(3) a {
      background: transparentize($menuBg, 0.4); }

    &:nth-child(4) a {
      background: transparentize($menuBg, 0.3); }

    &:nth-child(5) a {
      background: transparentize($menuBg, 0.2); }

    &:nth-child(6) a {
      background: transparentize($menuBg, 0.1); }

    &.active a {
      color: #efe6e6;
      background: transparentize($bfred, 0.3);
      width: 277px; }

    &:first-child a {
      margin-top: 0; } } }
