#site-footer {
  margin-top: 250px;
  margin-left: $mainWidth;
  text-align: center;
  color: $textColorNegative2;
  font-size: 14px;
  line-height: 1.4em;

  a {
    color: $textColorNegative2; }

  address {
    margin-bottom: 2em; }

  .item {
    margin-bottom: 0.6em;
    text-align: center;

    .label {
      padding-right: 0.6em;
      color: $textColorNegative; } }

  .external-links {

    a {
      margin: 30px auto; }

    a.deer-nz {
      @include replace-text("./images/logo-deer-nz.png");
      display: block;
      width: 140px;
      height: 39px;
      opacity: 0.8;

      &:hover {
        opacity: 1; } }

    a.forestpack {
      @include replace-text("./images/logo-forestpack.png");
      display: block;
      width: 128px;
      height: 34px;
      opacity: 0.8;

      &:hover {
        opacity: 1; } }

    a.deer-ai {
      @include replace-text("./images/logo-deer-ai.png");
      display: block;
      width: 140px;
      height: 44px;
      opacity: 0.8;

      &:hover {
        opacity: 1; } } } }
