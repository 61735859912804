/*
 * Hide visually and from screen readers:
 * http://juicystudio.com/article/screen-readers-display-none.php
 */

.hidden {
    display: none !important;
    visibility: hidden; }

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto; }

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
    visibility: hidden; }


.clearfix {
    &:before,
    &:after {
        content: " ";
        display: table; }

    &:after {
        clear: both; } }

@mixin hide-text() {
    $approximate-em-value: 12px / 1em;
    $wider-than-any-screen: -9999em;
    /*text-indent: $wider-than-any-screen * $approximate-em-value*/
    text-indent: -119988px;

    overflow: hidden; }

@mixin replace-text($img, $x: 0, $y: 0) {
    @include hide-text;
    background: {
        image: url($img);
        repeat: no-repeat;
        position: $x $y; } }
