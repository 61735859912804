// Colors
$bfred: #AF010A;
$bfred2: #AB5C59;
$bfred3: #bc000e;
$bfgreen: #6eac3e;
$bfgreen2: saturate(lighten($bfgreen, 20%), 20%);
$bfwhite: #fff;
$bfblack: #2E2E2E;

$bfLinkColor: #966;
$bfLinkColorHover: #a44;

$mainBg: #eee6e6;
$mainSummaryBg: #fff;
$menuBg: #464a52;

$backColor: #424242;
$backGradColor1: #1d1d1d;
$backGradColor2: #424242;

$baseColor: #280E0F;
$baseGradColor1: #220a0a;
$baseGradColor2: #3b1918;
$pageColor: #625c5c;

$textColor: #2e2e2e;
$textColorMid: #dacecf;
$textColorNegative: #ccc;
$textColorNegative2: #fff;
$textColorNegativeH: #c47976;

// Dimensions
$bodyWidth: 1000px;
$mainWidth: 750px;
$menuWidth: 237px;

$menuHeight: 251px;
$spacing: 15px;

$logoHeight: 178px;
$logoElHeight: $logoHeight + ($spacing * 2);
