$promoColumnWidth: 225px;

#page-header {
  width: $mainWidth;
  height: $menuHeight;
  float: left;
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-color: $bfwhite;
  background-image: url('./images/headers/sales.jpg');

  &:before {
    content: '';
    display: block;
    width: 750px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: bottom left;
    position: absolute;
    top: -50px;
    left: 0; } }

#promo {
  background: url('./images/promo-animal-low.png') no-repeat;
  background-position: 161px top;
  margin-left: 237px;
  padding: 55px 25px 0 25px;
  position: relative;
  top: -55px;
  height: 401px;
  font-size: 12px;
  line-height: 1.3em;

  h2 {
    @include font-promo-title;
    text-transform: uppercase;
    color: $bfred3;
    font-size: 28px;
    line-height: 1em;
    margin: 54px 0 15px 0;
    text-shadow: 0 0 3px rgba(255, 255, 255, 0.5); }

  p {
    color: $bfwhite;
    letter-spacing: 1px;
    margin: 0 0 5px 0;
    line-height: inherit; }

  #newsletter-blurb {
    margin-top: 107px; }

  .prizes {
    margin-top: 15px;

    div {
      width: 47%;
      float: left;

      &+div {
        margin-left: 6%; } }

    h3 {
      @include font-promo-title;
      background-color: $bfgreen;
      color: $bfwhite;
      font-size: 12px;
      line-height: 1em;
      padding: 4px;
      float: left;
      margin: 0;

      .numeral {
        font-size: 30px;
        line-height: 24px; }
      .suffix {
        font-size: 9px;
        position: relative;
        top: -13px; } }

    p {
      margin-left: 54px;

      strong {
        color: $bfgreen2; } } }

  .promo-button {
    padding-left: 0;
    padding-right: 0;
    width: $promoColumnWidth; }

  form {
    margin: 0; }

  @import "newsletter-signup"; }

#page-home {
  #page-header {
    height: 401px;
    background-image: url('./images/headers/home-promo.jpg'); } }

#page-news {
  #page-header:before {
    background-image: url('./images/headers/news-top.png'); }
  #page-header {
    background-image: url('./images/headers/news.jpg'); } }

#page-sires {
  #page-header:before {
    background-image: url('./images/headers/sires-top.png'); }
  #page-header {
    background-image: url('./images/headers/sires.jpg'); } }

#page-trophy-stags {
  #page-header {
    background-image: url('./images/headers/trophy-stags.jpg'); } }

#page-sales {
  #page-header {
    background-image: url('./images/headers/sales.jpg'); } }

#page-about {
  #page-header:before {
    background-image: url('./images/headers/about-top.png'); }
  #page-header {
    background-image: url('./images/headers/about.jpg'); } }

#page-contact {
  #page-header:before {
    background-image: url('./images/headers/contact-top.png'); }
  #page-header {
    background-image: url('./images/headers/contact.jpg'); } }
